export const Pages = {
  ABOUT: '/about',
  HOME: '/',
  NOT_FOUND: '/not-found',
  SETTINGS: '/settings',
  GOOGLE_CONNECT: '/connexion-google',
  CONVERSATIONS: '/conversations',
  TEMPLATES: '/templates',
  TAGS: '/tags',
  ORGANIZATION: '/organization',
  LANDING: '/landing',
  REQUEST_RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD: '/redirect/reset-password',
  ADMIN: '/admin',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  DATA_POLICY: '/data-policy',
  TERMS_OF_USE: '/terms-of-use',
  VERIFICATION: '/redirect/verification',
  GENERAL_SETTINGS: '/settings/general',
  SECURITY_SETTINGS: '/settings/security',
  NOTIFICATIONS_SETTINGS: '/settings/notifications',
  TRANSCRIPTION_SETTINGS: '/settings/transcription',
  SUMMARY_SETTINGS: '/settings/summary',
  PLAN_SETTINGS: '/settings/plan',
  BILLING_SETTINGS: '/settings/billing',
  BILLING_PAYMENT_METHODS: '/settings/billing/payment-methods',
  BILLING_HISTORY: '/settings/billing/history',
  INTEGRATIONS_SETTINGS: '/settings/integrations',
}
