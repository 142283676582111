import { authTokensState } from '@atoms/auth.atom'
import { userState } from '@atoms/user.atom'
import { Pages } from '@constants/routes.constant'
import React, { lazy } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

// Lazy load the public and authenticated routers
const PublicRouter = lazy(() => import('./public.router'))
const AuthenticatedRouter = lazy(() => import('./authenticated.router'))

interface WrappedRouteProps {
  children: React.ReactNode
}

const ProtectedRoute = ({ children }: WrappedRouteProps) => {
  const authTokens = useRecoilValue(authTokensState)
  return authTokens?.refreshToken ? <>{children}</> : <Navigate to={Pages.SIGN_IN} />
}

const AnonymousRoute = ({ children }: WrappedRouteProps): JSX.Element => {
  const authTokens = useRecoilValue(authTokensState)
  const user = useRecoilValue(userState)
  return user?.id && authTokens?.refreshToken ? <Navigate to={Pages.HOME} /> : <>{children}</>
}

const Router: React.FC = () => {
  const location = useLocation()

  return (
    <Routes>
      {/* Public routes */}
      <Route
        path={Pages.SIGN_IN}
        element={
          <AnonymousRoute>
            <PublicRouter path={location.pathname} />
          </AnonymousRoute>
        }
      />
      <Route
        path={Pages.SIGN_UP}
        element={
          <AnonymousRoute>
            <PublicRouter path={location.pathname} />
          </AnonymousRoute>
        }
      />
      <Route
        path={Pages.RESET_PASSWORD}
        element={
          <AnonymousRoute>
            <PublicRouter path={location.pathname} />
          </AnonymousRoute>
        }
      />
      <Route
        path={Pages.VERIFICATION}
        element={
          <AnonymousRoute>
            <PublicRouter path={location.pathname} />
          </AnonymousRoute>
        }
      />
      <Route
        path={Pages.DATA_POLICY}
        element={
          <AnonymousRoute>
            <PublicRouter path={location.pathname} />
          </AnonymousRoute>
        }
      />
      <Route
        path={Pages.TERMS_OF_USE}
        element={
          <AnonymousRoute>
            <PublicRouter path={location.pathname} />
          </AnonymousRoute>
        }
      />

      {/* Protected routes */}
      <Route
        path={Pages.HOME}
        element={
          <ProtectedRoute>
            <AuthenticatedRouter path={location.pathname} />
          </ProtectedRoute>
        }
      />
      <Route
        path={Pages.CONVERSATIONS + '/*'}
        element={
          <ProtectedRoute>
            <AuthenticatedRouter path={location.pathname} />
          </ProtectedRoute>
        }
      />
      <Route
        path={Pages.TEMPLATES}
        element={
          <ProtectedRoute>
            <AuthenticatedRouter path={location.pathname} />
          </ProtectedRoute>
        }
      />
      <Route
        path={Pages.TAGS}
        element={
          <ProtectedRoute>
            <AuthenticatedRouter path={location.pathname} />
          </ProtectedRoute>
        }
      />
      <Route
        path={Pages.SETTINGS + '/*'}
        element={
          <ProtectedRoute>
            <AuthenticatedRouter path={location.pathname} />
          </ProtectedRoute>
        }
      />

      {/* Fallback route */}
      <Route path="*" element={<Navigate to={Pages.HOME} replace />} />
    </Routes>
  )
}

export default Router
